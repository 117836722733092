<template>
  <div>
    <br />
    <p>
      Esta métrica permite comparar las estimaciones de Aquacorp frente a los valores de referencia
      del laboratorio.
    </p>
    <br />
    <p>
      <strong>MAE <em>(Mean Absolute Error)</em>.</strong> Determina la precisión de la técnica de
      predicción frente a los datos reales mediante el cálculo de la diferencia absoluta promedio
      entre los dos valores a lo largo del tiempo.
    </p>
    <br />
    <p>Se define de acuerdo a la siguiente fórmula:</p>
    <br />
    <div class="equation center">
      <img src="/img/iMae.png" alt="mae" class="equation-image" /><br />
    </div>
    <p>
      La <strong>y</strong> representa la estimación realizada por la tecnología de Aquacorp,
      <strong>x</strong> el valor de referencia del laboratorio y <strong>N</strong> el número de
      pares (estimaciones/valor de referencia) evaluados.
    </p>
  </div>
</template>

<script lang="ts" setup></script>

<style scoped>
.center {
  text-align: center;
}

.equation-image {
  max-width: 80%;
  height: 150px;
}
p {
  word-break: keep-all;
}
</style>
